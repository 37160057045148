import Base from '../../_config/base';

class Header extends Base {
  constructor(el) {
    super(el);

    this.hamburger = el.querySelector('#hamburger-icon');
    this.parentItem = el.querySelector('.has-children > a');
    this.hamburgerMenu = el.querySelector('.hamburger-menu');
    this.logo = el.querySelector('.header__logo');
    this.body = document.getElementById('body');
    const header = el;
    const headerBtns = [...el.querySelectorAll('.hamburger-menu__item')];

    this.hamburger.onclick = (e) => {
      e.preventDefault();
      this.hamburger.classList.toggle('active');
      this.logo.classList.toggle('header__logo--active');
      this.body.classList.toggle('overflow-hidden');
      el.classList.toggle('active');
      this.hamburgerMenu.classList.remove('active');
    };

    headerBtns.forEach((btn) => {
      btn.onclick = () => {
        this.hamburger.classList.toggle('active');
        this.logo.classList.toggle('header__logo--active');
        this.body.classList.toggle('overflow-hidden');
        el.classList.toggle('active');
        this.hamburgerMenu.classList.remove('active');
      };
    });

    let oldValue = 0;
    window.addEventListener('scroll', () => {
      // Get the new Value
      const newValue = window.pageYOffset;

      // Subtract the two and conclude
      if (newValue < 5) {
        header.classList.remove('inactiveHeader');
        header.classList.remove('activeHeader');
      } else if (oldValue - newValue > 0) {
        // up
        header.classList.add('activeHeader');
        header.classList.remove('inactiveHeader');
      } else if (oldValue - newValue < 0) {
        // down
        header.classList.add('inactiveHeader');
        header.classList.remove('activeHeader');
        header.classList.add('activeHeader');
      }

      // Update the old value
      oldValue = newValue;
    });

    // get element by rel attribute
    const favicon = document.querySelector('link[rel="shortcut icon"]');
    const faviconArray = ['/favicon/favicon.ico', '/favicon/favicon1.ico', '/favicon/favicon2.ico'];

    // pick random array element
    const randomFavicon = faviconArray[Math.floor(Math.random() * faviconArray.length)];

    // change favicon to randomFavicon
    favicon.href = randomFavicon;
  }
}

Header.className = 'Header';
export default Header;
