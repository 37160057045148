import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import Base from '../../_config/base';

class Projects extends Base {
  constructor(el) {
    super(el);
    const slider = el.querySelector('.swiper');
    const allProjects = [...el.querySelectorAll('.projects__project__slide')];
    const allFilterBtn = [...el.querySelectorAll('.projects__filter__item')];

    const sliderOpt = {
      modules: [Navigation, Pagination],
      slidesPerView: 'auto',
      spaceBetween: 18,
      pagination: {
        el: '.swiper-pagination',
        type: 'progressbar',
      },
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        768: {
          slidesPerView: 'auto',
          slidesPerGroup: 3,
        },
      },
    };

    // eslint-disable-next-line
    const swiper = new Swiper(slider, sliderOpt);

    const checkSlidesVisibility = () => {
      if (slider.querySelector('.swiper-pagination').classList.contains('swiper-pagination-lock')) {
        slider.querySelector('.swiper-wrapper').classList.add('justify-content-center');
      } else {
        slider.querySelector('.swiper-wrapper').classList.remove('justify-content-center');
      }
    };

    checkSlidesVisibility();

    swiper.on('resize', () => {
      checkSlidesVisibility();
    });

    allProjects.forEach((project) => {
      // clickevent, add class projects__project__slide--open
      project.addEventListener('mouseenter', () => {
        project.classList.add('projects__project__slide--open');
        swiper.update();
      });
      project.addEventListener('mouseleave', () => {
        project.classList.remove('projects__project__slide--open');
        swiper.update();
      });
    });

    allFilterBtn.forEach((btn) => {
      btn.addEventListener('click', () => {
        // remove active class from all buttons
        allFilterBtn.forEach((b) => {
          b.classList.remove('projects__filter__item--active');
        });

        // add active class to clicked button
        btn.classList.add('projects__filter__item--active');

        // filter projects
        const filterTopic = btn.dataset.filtertopic;
        allProjects.forEach((project) => {
          if (filterTopic === 'Alle') {
            project.classList.remove('projects__project__slide--hide');
          } else if (project.dataset.topic === filterTopic) {
            project.classList.remove('projects__project__slide--hide');
          } else {
            project.classList.add('projects__project__slide--hide');
          }
        });

        // check if there are no projects left
        const allProjectsVisible = allProjects.filter((project) => !project.classList.contains('projects__project__slide--hide'));
        if (allProjectsVisible.length === 0) {
          el.querySelector('.projects__error').classList.remove('d-none');
        } else {
          el.querySelector('.projects__error').classList.add('d-none');
        }

        swiper.slideTo(0);
        swiper.update();
      });
    });
  }
}

Projects.className = 'Projects';
export default Projects;
